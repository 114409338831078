import React from 'react';
import Seo from 'components/seo';
import ErrorSec from 'containers/Error';
import { ResetCSS } from 'common/assets/css/style';

const NotFoundPage = () => (
  <>
 <div>
  404: Not found, please try again 
 </div>
  </>
);

export default NotFoundPage;
